import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserHandlerService } from '../../user-handler.service';
import { Permission } from 'src/app/model/enums';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KitchenGuard {

  constructor(private _router: Router, private userHandlerService: UserHandlerService, private adal: AdalService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //regular auth
    let token = localStorage.getItem("token");
    if (token){
      //permission
      await this.userHandlerService.initCurrentUser();
      let permission = this.userHandlerService.user.permission;
      if (permission == Permission.Kitchen){
        // //adal auth
        // if(!environment.withAzure) return true;
        // if(this.adal.userInfo.authenticated) 
        //   return true;
        return true;
      } 
    }

    this._router.navigate(['login']);
    return false;
  }
}
