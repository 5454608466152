import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() error: boolean;
  @Input() errorText: string;
  @Input() type: string;
  @Input() value: string;
  @Output() changeEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    if(!this.value){
      this.value = "";
    } else{
      this.changeEvent.emit(this.value);
    }
  }

  onChange(value: string){
    this.changeEvent.emit(value);
  }
}
