import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Table } from '../classes/Table';
import { OrderStatus } from '../../model/enums';
import Order from '../../classes/Order';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData: Table<any>;
  @Output() checkboxChangeEvent = new EventEmitter<object>();
  @Output() clickLineEvent = new EventEmitter<object>();
  @Output() toggleChangeEvent = new EventEmitter<object>();
  @Output() iconClickedEvent = new EventEmitter<number>();
  @Input() showPagination: boolean;
  p: number = 1;
  constructor() { }

  ngOnInit() {
   var x = this.tableData; 
  }

  isItemActive(item){
    let checkboxField = this.tableData.headlines.find(header => header.type == "checkbox");
    if(!checkboxField) return false;
    return item[checkboxField.name.fieldName];
  }
  isItemNotActive(item){
    return( item instanceof Order && item.status == OrderStatus.Supplied);
  }

  checkboxHandler(itemId, key, value){
    this.checkboxChangeEvent.emit({itemId, key, value});
  }
  toggleHandler(item, key, value){
    item.active = !item.active;
    item.isActiveDisplayBoolean = item.active;
    this.toggleChangeEvent.emit({item, key, value});
  }
  iconClickedHandler(itemId){
    this.iconClickedEvent.emit(itemId);
  }
  clickLine(item){
    if(this.tableData.clickLine){
      this.clickLineEvent.emit(item);
    }

  }
  

}
