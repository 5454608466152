import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() value: string;
  @Input() checked: boolean;
  @Output() changeHandler = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {
    var x = this.checked;
    
  }

  onChange(value: boolean){
    this.changeHandler.emit(value);
  }
}
