import { Injectable } from '@angular/core';
import { User } from '../classes/User';
import { UserHandlerService } from './user-handler.service';
import { HttpHandlerService } from './http-handler.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  token: unknown;
  emailOfUser: any;
  full_user_dateils: any;
  

  constructor(private http:HttpClient,private userHandlerService:UserHandlerService, private httpHandlerService: HttpHandlerService, private router: Router, private adal: AdalService) { }
  login(user:User){
    //TODO:login verificate and after send to set user in localStorage
    this.userHandlerService.setCurrentUser(user);
   
  }
  
  

  getTokenAndGetData(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      await this.httpHandlerService.get('/auth/getToken').subscribe((response) => {
        this.emailOfUser=localStorage.getItem('username');
        console.log( this.emailOfUser)
        this.token =response['data'];
          // Token obtained successfully
          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.token,
            'Content-Type'	:'application/json'
          });
          this.http.get<any>(
            `https://graph.microsoft.com/v1.0/users/${this.emailOfUser}/?$select=userprincipalname,employeeid,mobilePhone`,
            { headers: headers }
          )
          .subscribe(
            (res) => {
              console.log(res);
              this.full_user_dateils=res
              console.log(this.full_user_dateils.employeeId)
             localStorage.setItem('emp_id',this.full_user_dateils.employeeId);
             localStorage.setItem('phone_number',this.full_user_dateils.mobilePhone);
              if (res.value && res.value.length > 0) {
                resolve(res.value[0].userPrincipalName);
              } else {
                reject({ code: 7 });
              }
            },
            (err) => {
              reject(err);
            }
          );
       
        // Do something with the data
      });
 
    });
    
  }

  async isLoggedIn() {
    try{
     const user = await this.httpHandlerService.get('/users/me').toPromise();
     if (user && user["user"]) { 
       var newUser = new User(user["user"]);
      this.userHandlerService.setCurrentUser(newUser);
      this.getShiftManagerNameByEmployeeNumber(newUser.shift_manager_employee_number);
       return true;
     } else {
       // do something else
       return false;
     }
    }catch(e){
      console.error(e);
      //this.router.navigate(["login"]);
      throw e;
    }
    
   }
    getShiftManagerNameByEmployeeNumber(employeeNumber){
     this.httpHandlerService.get('/users/?user[employee_number]='+employeeNumber).subscribe(res=>{
      if (res && res["users"] && res["users"].length > 0 ) { 
        var newUser = new User(res["users"][0]);
       this.userHandlerService.user["shift_manager_employee_name"] = newUser.name;
       
        return true;
      } 
     });
   }

  logout(){
    
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    // console.log('before adal')
    // try {
    //   this.adal.logOut();
    //   console.log('in adal')
    // } catch (error) {
    //   console.log('error adal')
    // }
    // console.log('bafter adal')
    if(this.adal.userInfo.authenticated) this.adal.logOut();
    else this.router.navigate(["login"]);
  }

  getAzureUser(){
    this.httpHandlerService.getMe().subscribe(data => {
      console.log(data);
      console.log(this.userHandlerService.user);
      if(data && this.userHandlerService.user){
        console.log((data as any).employeeId);
        console.log(this.userHandlerService.user.employee_number);
        if((data as any).employeeId != this.userHandlerService.user.employee_number){
          alert('יש חוסר התאמה בין משתמש Azure לבין משתמש מערכת הזמנת ארוחות');
          this.logout();
          window.location.reload();
        }
      }
      
    }, err => {
      console.log(err)
    });
  }
}
