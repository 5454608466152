export enum Permission {
    ShiftManager = "shiftManager", //אחמ"ש
    Employee = "employee",//עובד רגיל
    Admin = "admin",
    EmployeeWithoutManager = "employeeWithoutManager",
    Kitchen = "kitchen"
}

export enum ActiveStatus {
    Active = "Active",
    Inactive = "Inactive"
}

export enum MealType {
    Night = "Night",
    Lunch = "Lunch",
    Dinner = "Dinner"
}

export enum BreakfastOption {
    Take = "Take",
    Donate = "Donate",
    DairyDish = "DairyDish",
    MeatDish = "MeatDish",
    NotTake = "NotTake",
    Empty = "",
}
export enum Breakfast {
    Donate = 3,
    DairyDish = 2,
    MeatDish = 1,
    NotTake = 0,
}

export enum MealStatus {
    Pending = "pending",
    Approved = "Approved"
}

export enum OrderStatus {
    Pending = "pending",
    Supplied = "supplied"
}
export enum ShiftCode {
    shift1 = "602",
    shift2 = "604",
    shift3 = "605"
}
