export class Table<T> {
    headlines: Headline[];
    data: T[];
    clickLine?: boolean;
}
export class Headline {
    name: Name;
    size: number;
    type?: string;
    iconName?: string;
}
class Name {
    text: string;
    fieldName: string;
}
