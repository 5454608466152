// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // httpUrl: "https://f45rmlm2a3.execute-api.us-east-2.amazonaws.com/prod", 
  // httpUrl: "https://meals-server.icldig.icl-group.com", 
  
    // httpUrl: "http://localhost:3000", 
    //  httpUrl: "https://api.fcmeal-dev.appsc.icldig.icl-group.com", 
  
    httpUrl: "https://api.fcmeal.appsb.icldig.icl-group.com", 
 adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: '4a5bad66-d7df-4a94-a829-1df86af7238a',
          // redirectUri: "https://fcmeal-dev.appsc.icldig.icl-group.com/postLogin",
          //  postLogoutRedirectUri: "https://fcmeal-dev.appsc.icldig.icl-group.com/login",
          //  redirectUri: "http://localhost:4200/postLogin",
          // postLogoutRedirectUri: "http://localhost:4200/login",
     redirectUri: "https://fcmeal.appsb.icldig.icl-group.com/postLogin",  //NEW PROD 
       postLogoutRedirectUri: "https://fcmeal.appsb.icldig.icl-group.com/login",

    popUp: false,
    cacheLocation: 'localStorage',
      // resource: 'https://graph.microsoft.com/default'
  },
  withAzure: true,
  addAzureHeader: true,
  checkSameUser: false,
  firebaseConfig: {
    apiKey: "AIzaSyC3J8iKFkaRgi4DrkiMyhjLON67qOGbHJ8",
    authDomain: "icl-meals-order.firebaseapp.com",
    databaseURL: "https://icl-meals-order.firebaseio.com",
    projectId: "icl-meals-order",
    storageBucket: "icl-meals-order.appspot.com",
    messagingSenderId: "720874595162",
    appId: "1:720874595162:web:6dd4e10fff226127988963",
    measurementId: "G-GBVKG5P5PJ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
