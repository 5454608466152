import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss']
})
export class RadioBtnComponent implements OnInit {
  @Input() radiobtnArray: Array<RadioBtn>;
  @Input() category: string;
  @Input() selectedValue: string;
  @Output() changeEvent = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit() {

  }

  onChange(selectedIndex){
    this.selectedValue = this.radiobtnArray[selectedIndex].value;
    this.changeEvent.emit(this.selectedValue);
  }

}
export class RadioBtn {
  name: string;
  value: string;
  label: string;
}