import { OrderStatus } from '../model/enums'

export default class Order{
    _id: number
    date: number //timestamp of start of the day
    shift_id: number //id
    shift_manager_id: number //id
    unit_id: string //id
    kitchen_id: number //id
    meals: number[] //array of ids
    status: OrderStatus //pending, supplied
    statusIcon?: string
    shiftManager?: shiftManagerName
    shiftManagerFullNmae?: string
    unit: Unit
    unitDesc: string
    site_id?:number;
    pickup_id?:number;
    comment?:string;
    constructor(order?) {
        for(let key in order){
            this[key] = order[key];
        }
        if(this.status == OrderStatus.Pending){
           this.statusIcon = "red-circle.png"; 
        } else{
            this.statusIcon = "greenv.png";
        }
        if(this.shiftManager)
        this.shiftManagerFullNmae = this.shiftManager.first_name+ ' ' + this.shiftManager.last_name;
        if(this.unit){
            this.unitDesc = this.unit.desc;
        } else{
            this.unitDesc = "";
        }
        }
}
export  class shiftManagerName {
    first_name: string
    last_name: string
} 
export  class Unit {
    desc: string
} 