import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginGuard } from './services/guard/login-guard.service';
import { KitchenGuard } from './services/guard/kitchen-guard/kitchen-guard.service';
import { AdminGuardS } from './services/guard/admin-guard/admin-guard.service';
import { PostLoginComponent } from './screens/post-login/post-login.component';
import { AppComponent } from "./app.component";
import { AdalGuard } from "adal-angular4";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "prefix" },
  {
    path: "employee",
    loadChildren: "./employee/employee.module#EmployeeModule",
  },
  {
    path: 'login', 
    loadChildren: './screens/login/login.module#LoginModule',
    canActivate: [AdalGuard, LoginGuard]
  },
   {path: 'postLogin', component: PostLoginComponent},
  { 
    path: "admin", 
    loadChildren: "./admin/admin.module#AdminModule",
    canActivate: [AdminGuardS]
  },
  { 
    path: "kitchen", 
    loadChildren: "./kitchen/kitchen.module#KitchenModule",
    canActivate: [KitchenGuard]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'prefix' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
