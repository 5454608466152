import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpHandlerService } from './services/http-handler.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdalService, AdalInterceptor, AdalGuard } from 'adal-angular4';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { MyHttpInterceptor } from './services/my-http-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { PostLoginComponent } from './screens/post-login/post-login.component';

@NgModule({
  declarations: [
    AppComponent,
    PostLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedComponentsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    HttpHandlerService, 
    AdalService, 
    AdalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdalInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
