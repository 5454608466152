import { Injectable } from '@angular/core';
import { HttpHandlerService } from '../http-handler.service';
import Shift from '../../classes/Shift';
import { first } from "rxjs/operators";
import { UserHandlerService } from '../user-handler.service';
import { Permission, ShiftCode } from 'src/app/model/enums';

@Injectable({
  providedIn: 'root'
})
export class ShiftHandlerService {
  currentShift: Shift;
  shiftsArray: Shift[];

  constructor(private httpHandlerService: HttpHandlerService, private userHandlerService: UserHandlerService) { }

  async getCurentShift() {
    try {
      const shift = await this.httpHandlerService.get('/shifts/current').pipe(first()).toPromise();
      if (shift && shift["shift"] && shift["shift"]["_id"]) {
        this.currentShift = new Shift(shift["shift"]);
      } else {
        //current time to order meal / create menu ended
        if(!this.shiftsArray || this.shiftsArray.length == 0){
          await this.getAllShifts();
        }
        this.shiftsArray.sort((a,b)=>{
          if(a.order_meal_end_time < b.order_meal_end_time) return -1;
          if(a.order_meal_end_time > b.order_meal_end_time) return 1;
          return 0;
        });

        //get time in HH:dd
        let currentHour: number|string = new Date().getHours();
        let currentMinutes: number|string = new Date().getMinutes();
        currentHour = currentHour < 10? '0' + currentHour: currentHour;
        currentMinutes = currentMinutes < 10? '0' + currentMinutes: currentMinutes;
        let now = currentHour + ':' + currentMinutes;
        
        //find last shift
        let userPermission = this.userHandlerService.user.permission;
        let pastShifts = [];
        if(userPermission == Permission.Kitchen) pastShifts = this.shiftsArray.filter(shift => shift.kitchen_end_time <=  now);
        else pastShifts = this.shiftsArray.filter(shift => shift.order_meal_end_time <= now);
        
        if(pastShifts.length > 0) this.currentShift = new Shift(pastShifts[pastShifts.length - 1]);
        else this.currentShift = new Shift(this.shiftsArray[0]);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  isValidTimeForKitchen(){
    // return true;
    if (!this.currentShift) return;
    if(!this.currentShift.kitchen_end_time) return;
    var startTime = this.currentShift.kitchen_start_time;
    var endTime = this.currentShift.kitchen_end_time;
    var hour = parseInt(endTime.split(':')[0]);
    var minutes = parseInt(endTime.split(':')[1]);
    var date = new Date();
    date.setHours(hour, minutes, 0);
    if(parseInt(endTime.split(':')[0]) < parseInt(startTime.split(':')[0]) ){
      date.setDate(new Date().getDate()+1);
    }
    var deadline = new Date(date.toJSON()).getTime();

    var now = new Date().getTime();
    
    var t = deadline - now;
    if (t > 0) {
      return true;
    }
    return false;
  }

  getNextShiftTimeKitchen() {
    // let shiftIndex = this.shiftsArray.findIndex(shift => shift._id == this.currentShift._id);
    // if(shiftIndex == -1) return;
    // if (shiftIndex == this.shiftsArray.length - 1) {
    //   return this.shiftsArray[0].kitchen_start_time;
    // }
    // else {
    //   return this.shiftsArray[shiftIndex + 1].kitchen_start_time;
    // }
    let nextShift;
    if(this.currentShift.code.toString() == ShiftCode.shift1){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift2)
    } else if(this.currentShift.code.toString() == ShiftCode.shift2){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift3)
    } else if(this.currentShift.code.toString() == ShiftCode.shift3){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift1)
    } 
    if(nextShift&& nextShift.length > 0){
      return nextShift[0].kitchen_start_time;
    }
    return;
  }

  isValidTimeForOrder() {
    // return true;
    if (!this.currentShift) return;
    var startTime = this.currentShift.kitchen_start_time;
    var endTime = this.currentShift.order_meal_end_time;
    var hour = parseInt(endTime.split(':')[0]);
    var minutes = parseInt(endTime.split(':')[1]);
    var date = new Date();
    date.setHours(hour, minutes, 0);
    if(parseInt(endTime.split(':')[0]) < parseInt(startTime.split(':')[0]) ){
      date.setDate(new Date().getDate()+1);
    }
    var deadline = new Date(date.toJSON()).getTime();

    var now = new Date().getTime();
    
    var t = deadline - now;
    if (t > 0) {
     return true;
    }
    return false;
  }

  getNextShiftTimeOrder() {
    // let shiftIndex = this.shiftsArray.findIndex(shift => shift._id == this.currentShift._id);
    // if(shiftIndex == -1) return;
    // if (shiftIndex == this.shiftsArray.length - 1) {
    //   return this.shiftsArray[0].order_meal_start_time;
    // }
    // else {
    //   return this.shiftsArray[shiftIndex + 1].order_meal_start_time;
    // }
    let nextShift;
    if(this.currentShift.code.toString() == ShiftCode.shift1){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift2)
    } else if(this.currentShift.code.toString() == ShiftCode.shift2){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift3)
    } else if(this.currentShift.code.toString() == ShiftCode.shift3){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift1)
    } 
    if(nextShift&& nextShift.length > 0){
      return nextShift[0].order_meal_start_time;
    }
    return;
  }

  async getAllShifts() {
    await new Promise((resolve, reject) => {
      this.httpHandlerService.get('/shifts').subscribe(res => {
        if (res && res["shifts"]) {
          if (res["shifts"].length > 0) {
            this.shiftsArray = res["shifts"].map(e => {
              return new Shift(e);
            });
  
          } else {
            console.log("error");
          }
        }
        resolve();
      }, error => {reject();});
    });
  }

  isValidTimeForAdmin(is_weekly_menu){
    // return true;
    if (is_weekly_menu) return true;
    if (!this.currentShift) return;
    if(!this.currentShift.admin_end_time) return;
    var startTime = this.currentShift.admin_start_time;
    var endTime = this.currentShift.admin_end_time;
    var hour = parseInt(endTime.split(':')[0]);
    var minutes = parseInt(endTime.split(':')[1]);
    var date = new Date();
    date.setHours(hour, minutes, 0);
    if(parseInt(endTime.split(':')[0]) < parseInt(startTime.split(':')[0]) ){
      date.setDate(new Date().getDate()+1);
    }
    var deadline = new Date(date.toJSON()).getTime();

    var now = new Date().getTime();
    
    var t = deadline - now;
    if (t > 0) {
      return true;
    }
    return false;
  }

  getNextShiftTimeAdmin() {
    // let shiftIndex = this.shiftsArray.findIndex(shift => shift._id == this.currentShift._id);
    // if(shiftIndex == -1) return;
    // if (shiftIndex == this.shiftsArray.length - 1) {
    //   return this.shiftsArray[0].kitchen_start_time;
    // }
    // else {
    //   return this.shiftsArray[shiftIndex + 1].kitchen_start_time;
    // }
    let nextShift;
    if(this.currentShift.code.toString() == ShiftCode.shift1){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift2)
    } else if(this.currentShift.code.toString() == ShiftCode.shift2){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift3)
    } else if(this.currentShift.code.toString() == ShiftCode.shift3){
       nextShift = this.shiftsArray.filter(shift => shift.code.toString() == ShiftCode.shift1)
    } 
    if(nextShift&& nextShift.length > 0){
      return nextShift[0].admin_start_time;
    }
    return;
  }
}

