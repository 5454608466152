import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Permission } from '../../model/enums';
import { HeaderNavigationBar } from '../classes/Header';
import { ThrowStmt } from '@angular/compiler';
import { ShiftHandlerService } from '../../services/shift-handler/shift-handler.service';
import Shift from '../../classes/Shift';
import { User } from '../../classes/User';
import { UserHandlerService } from '../../services/user-handler.service';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() permission: Permission;
  @Input() displayBtn: boolean;
  @Output() clickHandler = new EventEmitter<string>();
  endTime: string;
  humburgerIsOpen: boolean;
  type: string;
  currentShift: Shift;
  repeat_link: any;
  navigationBar: HeaderNavigationBar[];
  user: User;
  userDetailsIsOpen: boolean;
 
  kitchenNavigationBar: HeaderNavigationBar[] = [{
    text: 'ניהול תפריט',
    link: '/kitchen/menu'
  },
  {
    text: 'הזמנות',
    link: '/kitchen/orders'
  },
  {
    text: 'דו"ח כמויות',
    link: '/kitchen/mealsReport'
  }];
  employeeNavigationBar: HeaderNavigationBar[] = [
    {
    text: 'ארוחת בוקר שבועית',
    link: '/employee/weeklyBreakFastOrder',
  }
 ];
 ShiftManagerNavigationBar: HeaderNavigationBar[] = [
  {
    text: 'רשימת בקשות להזמנה',
    link: '/employee/mealsList',
  }
  ,{
  text: 'ארוחת בוקר שבועית',
  link: '/employee/weeklyBreakFastOrder',
}

];
 
  adminNavigationBar: HeaderNavigationBar[] = [
    {
    text: 'ניהול עובדים',
    link: '/admin/workersManagment'
  },
  {
    text: 'ניהול מנות',
    link: '/admin/mealsManagment'
  },
  {
    text: 'ניהול תפריט',
    link: '/admin/menu'
  },  
  {
    text: 'הזמנות',
    link: '/admin/orders'
  },
  {
    text: 'דוחות',
    link: '/admin/reports'
  }
  ,{
    text: 'ארוחת בוקר שבועית',
    link: '/employee/weeklyBreakFastOrder',
  }
];
  kitchenNavigationBarMobile: HeaderNavigationBar[] = [
  {
    text: 'ניהול מנות',
    link: '/admin/mealsManagment'
  },
  {
    text: 'ניהול תפריט',
    link: '/admin/menuManagment'
  }];
  employeeNavigationBarMobile: HeaderNavigationBar[] = [
  {
    text: 'הזמנת בוקר שבועית',
    link: '/employee/weeklyBreakFastOrder'
  }];
  adminNavigationBarMobile: HeaderNavigationBar[] = [
    {
      text: 'משמרת',
      link: '/admin/menu/shiftMenuManagment'
    },
    {
      text: 'בוקר שבועי',
      link: '/admin/menu/weeklyMenuManagment'
    },
    {
      text: 'צהריים שבועי',
      link: '/admin/menu/weeklyLunchMenuManagment'
    }];
  window: any;
  nonLunchSiteIds = [1,2,6,12]; // These site ids shouldn't see the tab of weekly lunch order
  constructor(private shiftHandlerService: ShiftHandlerService, private userHandlerService: UserHandlerService, private router: Router, private loginService: LoginService) { }

  ngOnInit() {
    if(localStorage.getItem('permission')=='admin'){
      this.employeeNavigationBar.push(
        {
          text: 'דף הבית',
          link: '/admin/workersManagment'
        }      
      )
    }
    if(localStorage.getItem('permission')=='shiftManager'){
      this.employeeNavigationBar.push(
        {
          text: 'דף הבית',
          link: '/employee/mealsList'
        }      
      )
    }
    if(localStorage.getItem('permission')=='employee'){
      this.employeeNavigationBar.push(
        {
          text: 'דף הבית',
          link: '/employee/mealOrder'
        }      
      )
    }
    this.window = window
    this.user = this.userHandlerService.user;
    this.currentShift = this.shiftHandlerService.currentShift;
    if(this.permission == Permission.Kitchen){
      this.getEndTimeForKitchen();
    }
    else if(this.permission != Permission.Admin) this.getEndTime();

    //navigation bar
    this.navigationBar = [];
    if(this.permission == Permission.Kitchen){
      this.navigationBar = this.kitchenNavigationBar;
    }else if(this.permission == Permission.Admin){  
      this.navigationBar = this.adminNavigationBar;
    }
    else if(this.permission == Permission.Employee){
    this.showLunchTab()
      this.navigationBar = this.employeeNavigationBar;
    }
    else if(this.permission == Permission.ShiftManager){
        this.navigationBar = this.ShiftManagerNavigationBar;
      }
    this.type = window.location.pathname;
    if(this.type == '/admin/orderDetails'){
      this.type = '/admin/orders';
    }
    if(this.type == '/kitchen/orderDetails'){
      this.type = '/kitchen/orders';
    }
    
  }

  showLunchTab() {
    if (this.nonLunchSiteIds.indexOf(this.userHandlerService.user.site_id) == -1) {
      this.employeeNavigationBarMobile.push(
        {
          text: 'הזמנת צהריים שבועית',
          link: '/employee/weeklyLunchOrder'
        }
      )
      this.employeeNavigationBar.push(
        {
          text: 'ארוחת צהריים שבועית',
          link: '/employee/weeklyLunchOrder'
        }      
      )
    }
  }
  openUserDetails(){
    this.userDetailsIsOpen = !this.userDetailsIsOpen;
  }
  manageMobileMenu(){
    this.humburgerIsOpen = !this.humburgerIsOpen;
  }
  sendToKitchenClickHandler(){
    this.clickHandler.emit('sendToKitchen');
  }

  exportClickHandler(){
    this.clickHandler.emit('export');
  }

  getEndTime(){
    if(!this.currentShift) return;
  var endTime = this.currentShift.order_meal_end_time;
  var hour = parseInt(endTime.split(':')[0]);
  var minutes = parseInt(endTime.split(':')[1]);
  //  this.endTime = (new Date((new Date()).getTime() + 3 * 60 * 60 * 1000)).toJSON();
  var date  = new Date();
  date.setHours(hour,minutes, 0); 
  if(parseInt(this.currentShift.order_meal_end_time.split(':')[0]) < parseInt(this.currentShift.order_meal_start_time.split(':')[0]) ){
    date.setDate(new Date().getDate()+1);
  }
  this.endTime = date.toJSON();
  }
  getEndTimeForKitchen(){
    if(!this.currentShift) return;
    var endTime = this.currentShift.kitchen_end_time;
  var hour = parseInt(endTime.split(':')[0]);
  var minutes = parseInt(endTime.split(':')[1]);
  //  this.endTime = (new Date((new Date()).getTime() + 3 * 60 * 60 * 1000)).toJSON();
  var date  = new Date();
  date.setHours(hour,minutes, 0); 
  if(parseInt(this.currentShift.kitchen_end_time.split(':')[0]) < parseInt(this.currentShift.kitchen_start_time.split(':')[0]) ){
    date.setDate(new Date().getDate()+1);
  }
  this.endTime = date.toJSON();
  }

  logout(){
    this.loginService.logout();
  }
}
