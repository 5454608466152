import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { UserHandlerService } from 'src/app/services/user-handler.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/model/enums';

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss']
})
export class PostLoginComponent implements OnInit {

  constructor(private _router: Router, private userHandlerService: UserHandlerService, private adal: AdalService) { }

  async ngOnInit() {
    const profile = await this.adal.getUser().toPromise();
    localStorage.setItem('username',profile.userName)
    const tok = await this.adal.acquireToken(environment.adalConfig.clientId).toPromise();
    localStorage.setItem('tok',tok)
    let is_shift_emp =  this.userHandlerService.user.is_shift_emp;
    await this.userHandlerService.initCurrentUser();
    let permission = this.userHandlerService.user.permission;
    console.log(this.adal.userInfo)
    if(environment.withAzure && (permission == Permission.Admin || permission == Permission.ShiftManager || permission == Permission.Employee ||permission == Permission.EmployeeWithoutManager|| permission == Permission.Kitchen) && !this.adal.userInfo.authenticated){
      return true;
    }
   
    switch(permission){
      case Permission.Admin:
        this._router.navigate(['admin']);
        break;
      case Permission.Kitchen:
        this._router.navigate(['kitchen']);
        break;
      case Permission.ShiftManager:
        if(is_shift_emp == 0){
          this._router.navigate(['employee','weeklyBreakFastOrder']);
          break;
        }
        this._router.navigate(['employee', 'mealsList']);
        break;
      case Permission.Employee:
        if(is_shift_emp == 0){
          this._router.navigate(['employee','weeklyBreakFastOrder']);
          break;
        }
        this._router.navigate(['employee']);
        break;
      case Permission.EmployeeWithoutManager:
        this._router.navigate(['employee']);
        break;
    }
    this._router.navigate(['login']);
  }

}
