import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedComponentsRoutingModule } from './shared-components-routing.module';
import { HeaderComponent } from './header/header.component';
import { ClockComponent } from './clock/clock.component';
import { SelectComponent } from './select/select.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { RadioBtnComponent } from './radio-btn/radio-btn.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TableComponent } from './table/table.component';
import { ComplexBtnComponent } from './complex-btn/complex-btn.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { ToggleComponent } from './toggle/toggle.component';
import { PopupComponent } from './popup/popup.component';
import { InputComponent } from './input/input.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SearchHeaderComponent } from './search-header/search-header.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { LoaderComponent } from './loader/loader.component';
import { NgxPaginationModule } from 'ngx-pagination';



@NgModule({
  declarations: [HeaderComponent, ClockComponent, SelectComponent, RadioBtnComponent, CheckboxComponent, TableComponent, ComplexBtnComponent, TableHeaderComponent, ToggleComponent,  PopupComponent, InputComponent, SearchHeaderComponent, DatePickerComponent, LoaderComponent],

  imports: [
    CommonModule,
    SharedComponentsRoutingModule,
    NgxPaginationModule,
    FormsModule,
    NgSelectModule,
    ClickOutsideModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    
  ],
 
  exports: [HeaderComponent, SelectComponent, RadioBtnComponent, CheckboxComponent, TableComponent, TableHeaderComponent, PopupComponent, ComplexBtnComponent, ToggleComponent, ClickOutsideModule, DatePickerComponent, MatDatepickerModule,  MatFormFieldModule,
    MatNativeDateModule, MatInputModule,SearchHeaderComponent, InputComponent, LoaderComponent ]
})
export class SharedComponentsModule { }
