import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UserHandlerService } from './user-handler.service';
import { AdalService } from 'adal-angular4';
import { mergeMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class HttpHandlerService {
  private baseUri = environment.httpUrl;
  

  buildHeaders(){
    const token = localStorage.getItem('tok')
    let headersOptions  = {
      headers: new HttpHeaders({'Content-Type': 'application/json',"authorization": "Bearer "+token,

      'x-azure-token':this.UserHandlerService.getAdalToken()?this.UserHandlerService.getAdalToken():'no token!',
      'X-token':this.UserHandlerService.getToken()?this.UserHandlerService.getToken():'no token!'
    })
    }
    return headersOptions
    
  }
  
  constructor(private http: HttpClient, private UserHandlerService: UserHandlerService, private adal: AdalService) { }

  get<T>(url, data?): Observable<T> {
    let obs = new Subject<T>();
      // var options = {};
      // if(typeof data !=='undefined'){
      //   options['params'] = data;
      // }
      this.http.get<T>(this.baseUri + url,this.buildHeaders()).subscribe(obs);
   return obs.asObservable();
  }
  post<T>(url, data): Observable<T> {
     
    let obs = new Subject<T>();
      this.http.post<T>(this.baseUri + url, data, this.buildHeaders()).subscribe(obs);
    return obs.asObservable();
  }
  put<T>(url, data): Observable<T> {
 
    let obs = new Subject<T>();
    this.http.put<T>(this.baseUri + url, data, this.buildHeaders()).subscribe(obs);
    return obs.asObservable();
  }
  
  delete<T>(url): Observable<T> {
    const headers   = new HttpHeaders({"authorization": "Bearer "+localStorage.getItem('tok'),"X-Token":this.UserHandlerService.getToken()})

    return this.http.delete<T>(this.baseUri + url, this.buildHeaders());
  }

  getJson<T>(url): Observable<T> {
    let obs = new Subject<T>();
      var options = {};
      this.http.get<T>(url).subscribe(obs);
   return obs.asObservable();
  }

  getMe<T>() {
    return this.adal.acquireToken("environment.adalConfig.resource").pipe(
     mergeMap((token) => {
        const headersParam = new HttpHeaders({
          'Content-Type': 'application/json;odata=verbose',
          'Authorization': 'Bearer ' + token.toString()
        });
        return this.http.get('https://graph.microsoft.com/v1.0/me?$select=employeeId', { headers: headersParam });
      })
    );
  }
}
