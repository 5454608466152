import { Permission, ActiveStatus } from '../model/enums';

export class User {
    _id: number
    company_id: number
    company: Company
    companyName: string
    createdAt: string
    deletedAt: string
    employee_number: number
    hilan_number: number
    first_name: string
    last_name: string;
    name: string
    permission: Permission | number //employee, shiftManager, admin, employeeWithoutManager, kitchen  
    shift_manager_employee_number: number
    unit_id: string //id
    unit?: UnitDesc
    unitName?: string;
    factory?: string //id
    kitchen_id: number //id
    site_id: number //id
    site: factoryName;
    siteName: string;
    sift_manager: ShiftManagerUser;
    shift_manager_id: number //id 
    shift_manager_name?: string //id 
    // status: ActiveStatus // active/inactive
    active: boolean
    inactiveDate: number //timestamp
    updatedAt: string
    permissionIcon?: string;
    shift_manager_employee_name?: string;
    in_office: number;
    Cost_Center_Code: string;
    is_shift_emp: 1 | 0 | null;


    hasRole(rolename: string): boolean {
        return this.permission == rolename;
    }

    constructor(user?) {
        for (let key in user) {

            this[key] = user[key];

        }
        if (this.permission || this.permission == 0) {
            this.setPermissionByNumber(this.permission)
        }
        if (this.permission) {
            switch (this.permission) {
                case 'employee':
                    this.permissionIcon = 'employee-blueDark.svg';
                    break;
                case 'admin':
                    this.permissionIcon = 'admin-blue.svg';
                    break;
                case 'shiftManager':
                    this.permissionIcon = 'manager-blue.svg';
                    break;
                case 'employeeWithoutManager':
                    this.permissionIcon = 'Employee-no-manager.svg';
                    break;
                case 'kitchen':
                    this.permissionIcon = 'kitchen-blue.svg';
                    break;
            }
        }
        this.name = this.first_name + ' ' + this.last_name;
        if (this.sift_manager) {
            this.shift_manager_name = this.sift_manager.first_name + ' ' + this.sift_manager.last_name;
        }
        if (this.site) {
            this.siteName = this.site.desc;
        }
        if (this.company) {
            this.companyName = this.company.name;
        }
        if (this.unit) {
            this.unitName = this.unit.desc;
        } else {
            this.unitName = "";
        }
    }
    setPermissionByNumber(permissionNumber) {
        switch (parseInt(permissionNumber)) {
            case 0:
                this.permission = Permission.Employee;
                break;
            case 1:
                this.permission = Permission.ShiftManager;
                break;
            case 2:
                this.permission = Permission.Admin;
                break;
            case 3:
                this.permission = Permission.EmployeeWithoutManager;
                break;
            case 4:
                this.permission = Permission.Kitchen;
                break;
        }

    }
}

export class factoryName {
    desc: string

}
export class ShiftManagerUser {
    employee_number: string;
    first_name: string;
    last_name: string;

}
export class Company {
    name: string;
}
export class UnitDesc {
    desc: string

}


