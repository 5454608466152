import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() title: string;
  @Input() defaultValue: string;
  @Input() selectData: Array<any>;
  @Input() disabled: boolean = false; 
  @Input() type: string;
  @Input() fieldValue: string;
  @Input() selectedItem: any;
  @Output() changeEvent = new EventEmitter<string>();
  @Input() isScrollDown: boolean;

  selectFocused: boolean = false;

  constructor() { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
   // event.target.innerWidth;
    if(this.isScrollDown){
    document.getElementById("selsectItem").scrollIntoView();
    }
  }
  ngOnInit() {
    if(this.selectedItem){
      this.changeEvent.emit(this.selectedItem);
    }
  }
  
  onChange() {
    this.changeEvent.emit(this.selectedItem);
  }
  
  selectClicked(){
   this.selectFocused = true;
  }

  unfocused(){
    this.selectFocused = false;
  }
//   focus(){
// if(this.isScrollDown){
//   document.getElementById("selsectItem").scrollIntoView();
  
// }

//   }
}
