import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupProps } from '../classes/Popup';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() props: PopupProps;
  @Output() eventHandler = new EventEmitter<object>();
  @Output() closePopupEvent = new EventEmitter<any>();

  private fieldsValues: object;

  constructor() { }

  ngOnInit() { 
    //init fields object
    this.fieldsValues = {};
    this.props.fields.forEach(field => {
      if(field.key) this.fieldsValues[field.key] = '';
    })
  }
  closePopup(){
    this.closePopupEvent.emit();
  }

  onClickHandler(onClickText: string) {
    //send the fields values
    this.eventHandler.emit({onClickText, data:this.fieldsValues});
  }

  onFieldChange(key, value){
    //save the new value 
    this.fieldsValues[key] = value;
  }
}