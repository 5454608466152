import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {FormControl} from '@angular/forms';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
//import { a_rollupMoment} from 'moment';

const moment =  _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() placeholder: string;
  @Output() changeEvent = new EventEmitter<any>();
  @Input() value: Date;
  constructor() { }

  ngOnInit() {
    
  }

  orgValueChange(value){
    var now = new Date();
    var date = new Date(value.value);
    // if(now.getDay()== date.getDay() && now.getMonth()==date.getMonth() && now.getFullYear() == date.getFullYear()){
    //   date.setHours(now.getHours());
    //   date.setMinutes(now.getMinutes());
    //   date.setSeconds(now.getSeconds());
    // }

     this.changeEvent.emit(date);
    
  }
  date = new FormControl(moment());

  flightSchedule = {
    date: new Date()
  } 

}
