import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserHandlerService } from '../user-handler.service';
import { Permission } from 'src/app/model/enums';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {

  constructor(private _router: Router, private userHandlerService: UserHandlerService, private adal: AdalService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.adal.userInfo.authenticated )
    {
      const tok = await this.adal.acquireToken(environment.adalConfig.clientId).toPromise();
      const profile = await this.adal.getUser().toPromise();
      console.log(profile.userName)
      localStorage.setItem('username',profile.userName)
      localStorage.setItem('tok',tok)
      
      return true;
    }
    await this.adal.login();
      
    return false;
  }

  
}
