import { Component, OnInit, Input } from '@angular/core';
import { UserHandlerService } from '../../services/user-handler.service';
import { Permission, ShiftCode } from '../../model/enums';
import { ShiftHandlerService } from 'src/app/services/shift-handler/shift-handler.service';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {
  @Input() deadlineTime: string;
  timer: string;
  text: string;
  deadline; 
  timerCountDown;
  shiftName: string;
  hideClock: boolean;
  constructor(private userHandlerService: UserHandlerService, private shiftHandlerService: ShiftHandlerService) { }

  ngOnInit() {
    // if(this.shiftHandlerService.currentShift.code == ShiftCode.shift3){
    //   this.hideClock = true;
    // }
    localStorage.setItem('currentShift',this.shiftHandlerService.currentShift.code);
    if(this.shiftHandlerService.currentShift && this.shiftHandlerService.currentShift.shiftName){
      this.shiftName = this.shiftHandlerService.currentShift.shiftName;
    } else{
      this.shiftName = "";
    }
    this.timerCountDownFunc();
    if(this.userHandlerService.user.permission == Permission.Kitchen){
      if(this.shiftHandlerService.currentShift.code == ShiftCode.shift1){
        this.shiftName = 'צהריים';
      }else if(this.shiftHandlerService.currentShift.code == ShiftCode.shift2){
        this.shiftName = 'ערב';
      } else{
        this.shiftName = "";
      }
      this.text = " זמן נותר לבניית תפריט " + this.shiftName;
    } else{
      this.text = "זמן נותר להזמנה";
    }
  }

  timerCountDownFunc(){
    this.timerCountDown = setInterval(() => {
    this.deadline = new Date(this.deadlineTime).getTime();
    var now = new Date().getTime();
    var t = this.deadline - now; 
    var hoursNumber = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
    if(hoursNumber < 10){
      var hours = '0' + hoursNumber;
    } else{
      var hours =  hoursNumber.toString();
    }
   var minutesNumber = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)); 
   if(minutesNumber < 10){
    var minutes = '0' + minutesNumber;
   } else{
    var minutes = minutesNumber.toString();
   }
   var secondsNumber = Math.floor((t % (1000 * 60)) / 1000); 
   if(secondsNumber < 10){
    var seconds = '0' + secondsNumber; 
   } else{
    var seconds =  secondsNumber.toString(); 
   }
    this.timer = hours + ":" + minutes + ":" + seconds; 
        if (t < 0) { 
            clearInterval(this.timerCountDown); 
            this.timer = "00:00:00"; 
        } 
    },1000)
  }
}
