import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() id: string;
  @Input() value: boolean;
  @Output() changeToggleEvent = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {}
  
  somethingChanged(value){
    this.value = !this.value;
    this.changeToggleEvent.emit(this.value);
  }
}
