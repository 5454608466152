import { Component, OnInit } from '@angular/core';
import { ShiftHandlerService } from './services/shift-handler/shift-handler.service';
import { Router, NavigationEnd } from "@angular/router";
import { LoginService } from './services/login.service';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { Papa } from 'ngx-papaparse';
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";



const app = initializeApp(environment.firebaseConfig);
getAnalytics(app);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'meals';
  showRootHtml: boolean;
  url: string;
  showLoader: boolean;
  constructor(private shiftHandlerService: ShiftHandlerService, private loginService: LoginService, private adal: AdalService, private _router: Router) {
    this.adal.init(environment.adalConfig);
    this.adal.handleWindowCallback();
  }

  async ngOnInit() {
    let isLogin = false;
    this.showLoader = true;
    try{
      isLogin = this.adal.userInfo.authenticated  &&  await this.loginService.isLoggedIn()
    }catch(e){
      this.showRootHtml = true;
      this.showLoader = false;
      this._router.navigate(['login']); //error - take to the login
    }
    if(isLogin){
      if (environment.checkSameUser && this.adal.getCachedToken(environment.adalConfig.clientId)){
        this.loginService.getAzureUser();
      }
      await this.shiftHandlerService.getAllShifts();
      await this.shiftHandlerService.getCurentShift();
    }
     
    else {
      this._router.navigate(['login']);
    }

    this.showLoader = false;
    this.showRootHtml = true;
  }

}
