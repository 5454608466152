import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-complex-btn',
  templateUrl: './complex-btn.component.html',
  styleUrls: ['./complex-btn.component.scss']
})
export class ComplexBtnComponent implements OnInit {
  @Input() type: string;
  @Input() image: string;
  @Input() text: string;
  @Output() clickHandler = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  onClick(){
    this.clickHandler.emit();
  }

}
