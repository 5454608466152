import { Component, OnInit, Input } from '@angular/core';
import { Table } from '../classes/Table';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  @Input() tableData: Table<any>;
  constructor() { }

  ngOnInit() {
  }

}
