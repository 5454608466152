import { MealType, ShiftCode } from '../model/enums'

//משמרת
export default class Shift {
    _id: number 
    type: MealType // lunch/dinner/night
    order_meal_start_time: string // hour in 5 digits ex.: 02:00 
    order_meal_end_time: string //  hour in 5 digits ex.: 02:00 
    kitchen_start_time: string // hour in 5 digits ex.: 02:00 
    kitchen_end_time: string //  hour in 5 digits ex.: 02:00 
    admin_start_time: string
    admin_end_time: string
    shiftName?: string
    code: string

    constructor(shift?) {
        for(let key in shift){
            this[key] = shift[key];
        }
        if(this.code){
            this.getShiftNameByCode(this.code)
        }
    }
    getShiftNameByCode(code){
        switch (code) {
            case ShiftCode.shift1:
                this.shiftName = 'בוקר';
                break;
            case ShiftCode.shift2:
                this.shiftName = 'צהריים';
                break;
            case ShiftCode.shift3:
                this.shiftName = 'ערב';
                break;
          
        }
    }
}
