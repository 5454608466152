import { Injectable } from '@angular/core';
import { User } from '../classes/User';
import { HttpHandlerService } from './http-handler.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserHandlerService {
  user: User;
  private token: string;
  constructor() { 
  //  this.initCurrentUser();
  }
  hasRole(rolename){
    return this.user.hasRole(rolename);
  }
  getCurrentUser():User{
    return this.user;
  }
  setCurrentUser(userData:User){
    this.user = new User(JSON.parse(JSON.stringify(userData)));
    localStorage.setItem("currentUser",JSON.stringify(this.user))
  }

  initCurrentUser(){
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
      this.user = new User(JSON.parse(currentUser));
    }
  }
  getAdalToken():string{
    return localStorage.getItem('adal.idtoken')
  }
  getToken(): string {
    if(this.token){
      return this.token;
    } 
    var token = localStorage.getItem("token");
    if(token){
      this.token = token;
    }
    return token;
   
}
setToken(token: string){
this.token = token;
localStorage.setItem('token', token);
}
}
