import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HeaderProps, FieldHeaderType, Tabs } from '../classes/searchHeader';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {
  @Input() props: HeaderProps;
  @Output() eventHandler = new EventEmitter<object>();
  @Output() eventHandlerChangeTab = new EventEmitter<string>();
  private fieldsValues: object;
  private activeTab: string;
  
  constructor() { }

  ngOnInit() { 
    //init fields object
    this.fieldsValues = {};
    this.props.fields.forEach(field => {
      if(field.key && field.value) this.fieldsValues[field.key] = field.value;
      else this.fieldsValues[field.key]
    })
    if(this.props.tabs && this.props.tabs.length > 0){
      this.activeTab = this.props.tabs[0].activeTab;
    }
    
  }
  changeActiveTab(field:Tabs){
    this.activeTab = field.activeTab;
    this.eventHandlerChangeTab.emit(field.activeTab);
  }
  onClickHandler(onClickText: string) {
    //send the fields values
    this.eventHandler.emit({onClickText, data:this.fieldsValues});
  }
  onFieldChange(key, value){
    //save the new value 
    this.fieldsValues[key] = value;
  }
}
